import * as React from "react"
import Layout from "../components/layout"
import { GatsbyImage } from "gatsby-plugin-image"
import { graphql } from 'gatsby'
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { Link } from "gatsby"
import {Col, Container, Row} from 'react-bootstrap'
import ArrowRight from '../../static/arrow-left.png';
import Moment from 'moment';

const NewsPost = ({ data }) => {
  const news = data.allContentfulNews.edges[0].node
  return (
    <Layout title={"Varmaorka - " + news.title}>
        <div className="py-offset">
          <Container>
            <Row className="align-items-center mb-2">
              <Col md={12}>
                <h2 className="section-title section-title-inner">{news.title}</h2>
              </Col>
            </Row>
            <div className="text-end post-meta mb-4">
              <p>{Moment(news.date).format('ll')}</p>
            </div>

            <div className="mb-5 post-image">
              <GatsbyImage image={news.image.gatsbyImageData} alt={news.image.description} />
            </div>

            <div className="post-content">{renderRichText(news.content)}</div>

            <div className="text-end pt-md-5 pt-4">
              <Link to={'/news'} activeClassName="active" className="btn-link">All news <img src={ArrowRight} alt="arrow-left" /></Link>
            </div>
          </Container>
        </div>
    </Layout>
  )
}

export const query = graphql`
query NewsPostBySlug(
    $slug: String!
) {
  allContentfulNews (filter: {slug: {eq: $slug}}){
    edges {
      node {
        image {
          gatsbyImageData
          description
        }
        title
        date
        slug
        content {
            raw
        }
      }
    }
  }
}`

export default NewsPost
